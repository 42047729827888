"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapSdkSourceSpecToSourceConnectorConfig = exports.convertSdkType = exports.makeSdkSource = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../types");
var lodash_1 = require("lodash");
var makeSdkSource = function (sdkSource) {
    return {
        protoType: "sdk_source",
        expertMode: false,
        pic: sdkSource.pic,
        displayName: sdkSource.displayName,
        id: sdkSource.id,
        collectionTypes: [],
        documentation: sdkSource.documentation,
        collectionParameters: [],
        staticStreamsConfigEndpoint: "/sdk_source/catalog?package=".concat(encodeURIComponent(sdkSource.package_name + "@" + sdkSource.package_version)),
        specEndpoint: "/sdk_source/spec?package=".concat(encodeURIComponent(sdkSource.package_name + "@" + sdkSource.package_version)),
        configParameters: [
            {
                displayName: "Sdk Source Package Name",
                id: "config.package_name",
                type: types_1.stringType,
                required: true,
                documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Sdk Source Package Name" }),
                constant: sdkSource.package_name,
            },
            {
                displayName: "Sdk Source Package Version",
                id: "config.package_version",
                type: types_1.stringType,
                required: true,
                documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Sdk Source Package Version" }),
                constant: sdkSource.package_version,
            },
        ],
        hasLoadableConfigParameters: true,
    };
};
exports.makeSdkSource = makeSdkSource;
var convertSdkType = function (type) {
    var tp = types_1.stringType;
    switch (type) {
        case "int":
            tp = types_1.intType;
            break;
        case "string":
        case undefined:
            tp = types_1.stringType;
            break;
        case "json":
            tp = types_1.jsonType;
            break;
        case "boolean":
            tp = types_1.booleanType;
            break;
        case "password":
            tp = types_1.passwordType;
            break;
        case "isoUtcDate":
            tp = types_1.isoUtcDateType;
            break;
        case "oauthSecret":
            tp = types_1.oauthSecretType;
            break;
        default:
            if (type["severalOf"]) {
                tp = (0, types_1.selectionType)(type["severalOf"], type["max"]);
            }
            else if (type["oneOf"]) {
                tp = (0, types_1.singleSelectionType)(type["oneOf"]);
            }
    }
    return tp;
};
exports.convertSdkType = convertSdkType;
/**
 * Maps the spec of the SdkSource connector to the Cience `Parameter` schema of the `SourceConnector`.
 * @param extensionDescriptor source plugin's descriptor.
 */
var mapSdkSourceSpecToSourceConnectorConfig = function (extensionDescriptor, availableOauthBackendSecrets) {
    var result = [];
    var configurationParameters = extensionDescriptor["configurationParameters"];
    configurationParameters.forEach(function (param) {
        var tp = (0, exports.convertSdkType)(param["type"]);
        if (availableOauthBackendSecrets && availableOauthBackendSecrets.includes(param["id"])) {
            tp = types_1.oauthSecretType;
        }
        var relevantIf = param["relevantIf"];
        result.push(__assign(__assign({}, param), { id: "config." + param["id"], type: tp, omitFieldRule: relevantIf
                ? function (config) { return (0, lodash_1.get)(config["config"], relevantIf["field"]) !== relevantIf["value"]; }
                : undefined }));
    });
    return result;
};
exports.mapSdkSourceSpecToSourceConnectorConfig = mapSdkSourceSpecToSourceConnectorConfig;
